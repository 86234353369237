import React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import { AiOutlineRight } from "react-icons/ai"

import "./article.scss"
import Layout from "../layouts/default"
import ShareService from "../services/share.service"
import DateTimeFormatService from "../services/date-time-format.service"
import CallToAction from "../components/call-to-action"
import SEO from "../components/seo"
import Section from "../components/structural/section"
import Html from "../components/paragraph/html"
import Image from "../components/paragraph/image"

export default ({ data }) => {
  const article = data.allNodeArticle.edges[0].node
  const title = article.title
  const description = article.field_description
  const created = article.created
  const author = article.relationships.uid
  const type = article.relationships.field_article_type

  // shareImage is passed to SEO component as OG:IMAGE metadata
  let shareImage = undefined
  try {
    shareImage = article.relationships.field_image.localFile.publicURL
  } catch {
    shareImage = false
  }

  //console.log(shareImage)

  return (
    <Layout id="single_article">
      <SEO title={title} description={description} image={shareImage} />
      <ArticleBreadcrumbs type={type} title={title} />
      <ArticleAuthor author={author} />
      <ArticleTitle title={title} created={created} />
      {!article.field_hide_image && article.relationships.field_image && (
        <ArticleImage
          image={article.relationships.field_image}
          alt={article.title}
        />
      )}
      <ArticleBody body={article.relationships.field_article_body} />
      <ArticleFooter />
      <CallToAction />
    </Layout>
  )
}

const ArticleBreadcrumbs = props => {
  let id = "ID_breadcrumbs"
  let background = { format: "gradient", data: "light-blue" }
  return (
    <Section id={id} background={background}>
      <Container>
        <Row>
          <Col>
            <p>
              <Link to="/articles">Articles</Link>
              <AiOutlineRight />
              <Link to={`/articles${props.type.path.alias}`}>
                {props.type.name}
              </Link>
              <AiOutlineRight />
              <span>{props.title}</span>
            </p>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

const ArticleAuthor = props => {
  let id = "ID_author"
  let background = { format: undefined }
  let first_name = props.author.field_first_name
  let last_name = props.author.field_last_name
  let job_title = props.author.field_job_title
  let image =
    props.author.relationships.user_picture &&
    props.author.relationships.user_picture.localFile
      ? props.author.relationships.user_picture.localFile
      : undefined

  if (first_name) {
    return (
      <Section id={id} background={background}>
        <Container>
          <Row>
            <div className={`author-card`}>
              {image && (
                <GatsbyImage
                  className="d-block m-auto"
                  imgStyle={{ objectFit: "cover" }}
                  image={{ ...image.childImageSharp.gatsbyImageData }}
                  // sizes={{ ...image.childImageSharp.gatsbyImageData }}
                  alt={`${first_name} ${last_name}`}
                />
              )}
              <p>
                <strong>
                  {first_name} {last_name}
                </strong>
              </p>
              <p>{job_title} - Safety Evolved</p>
            </div>
          </Row>
        </Container>
      </Section>
    )
  } else {
    return null
  }
}

const ArticleTitle = props => {
  let id = "ID_title"
  let background = { format: undefined }
  let created = props.created.split("T")
  return (
    <Section id={id} background={background}>
      <Container>
        <Row>
          <Col>
            <h1>{props.title}</h1>
            <DateTimeFormatService date={created[0]} style={{ fontSize: 14 }} />
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

const ArticleImage = props => {
  let id = "ID_feature_image"
  let background = { format: undefined }
  return (
    <Section id={id} background={background}>
      <Container>
        <Row>
          <GatsbyImage
            className="d-block w-100"
            imgStyle={{ objectFit: "cover" }}
            // sizes={{ ...props.image.localFile.childImageSharp.gatsbyImageData }}
            image={{ ...props.image.localFile.childImageSharp.gatsbyImageData }}
            alt={props.alt}
          />
        </Row>
      </Container>
    </Section>
  )
}

const ArticleBody = props => {
  let id = "ID_body"
  let background = { format: undefined }
  return (
    <Section id={id} background={background}>
      <Container>
        <Row>
          {props.body.map((item, index) => {
            let column = item.relationships
            return (
              <Col
                key={index}
                xs={column.field_column_spans.field_span_xs}
                md={column.field_column_spans.field_span_md}
                xl={column.field_column_spans.field_span_xl}
              >
                {column.field_components.map((p, index) => {
                  // Gracefully handle unknown types
                  let type
                  try {
                    type = p.internal.type
                  } catch (e) {}

                  switch (type) {
                    case "paragraph__component_html":
                      return <Html props={p} key={index} />
                    case "paragraph__component_image":
                      return <Image props={p} key={index} />
                    default:
                      return (
                        <p
                          key={index}
                          style={{
                            color: "red",
                            fontWeight: "bold",
                            background: "white",
                          }}
                        >
                          Failed to load component!
                        </p>
                      )
                  }
                })}
              </Col>
            )
          })}
        </Row>
      </Container>
    </Section>
  )
}

const ArticleFooter = props => {
  let id = "ID_footer"
  let background = { format: undefined }
  return (
    <Section id={id} background={background}>
      <Container>
        <Row>
          <Col>
            <p>
              Safety Evolved is part of FTS Group, one of the largest privately
              owned IT services companies in Australia. As well as Safety
              Evolved, FTS Group also has the businesses ANATAS, Cubic
              Consulting, FTS Data &amp; AI, Galaxy 42, KoBold, SME Gateway,
              Talos, and Veritec.
            </p>
            <ShareService />
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export const query = graphql`
  query($id: String!) {
    allNodeArticle(filter: { id: { eq: $id } }) {
      edges {
        node {
          id
          title
          created
          field_description
          field_hide_image
          path {
            alias
          }
          field_image {
            alt
          }
          relationships {
            field_article_type {
              path {
                alias
              }
              name
            }
            field_image {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
                publicURL
              }
            }
            field_article_body {
              relationships {
                field_column_spans {
                  field_span_md
                  field_span_xl
                  field_span_xs
                }
                field_components {
                  ... on paragraph__component_html {
                    internal {
                      type
                    }
                    field_html {
                      processed
                    }
                  }
                  ... on paragraph__component_image {
                    internal {
                      type
                    }
                    field_fluid
                    relationships {
                      field_image {
                        field_media_image {
                          alt
                        }
                        relationships {
                          field_media_image {
                            localFile {
                              childImageSharp {
                                gatsbyImageData(layout: FULL_WIDTH)
                              }
                              publicURL
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            field_article_type {
              id
              name
              path {
                alias
              }
            }
            uid {
              display_name
              field_first_name
              field_last_name
              field_job_title
              relationships {
                user_picture {
                  localFile {
                    childImageSharp {
                      gatsbyImageData(width: 100, layout: CONSTRAINED)
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
