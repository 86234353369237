import React from "react"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  TelegramShareButton,
  TwitterShareButton,
  ViberShareButton,
  WhatsappShareButton,
  WorkplaceShareButton,
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  RedditIcon,
  TelegramIcon,
  TwitterIcon,
  ViberIcon,
  WhatsappIcon,
  WorkplaceIcon,
} from "react-share"
import "./share.service.scss"

/*
 * Props:
 * - URL (optional)
 */

export default props => {
  // Use URL prop, else use current URL
  let url = props.url || (typeof window !== "undefined" && window.location.href)
  return (
    <div className={`share_service`}>
      <span>Share</span>
      <FacebookShareButton
        url={url}
        children={<FacebookIcon size={32} round={true} />}
      />
      <TwitterShareButton
        url={url}
        children={<TwitterIcon size={32} round={true} />}
      />
      <LinkedinShareButton
        url={url}
        children={<LinkedinIcon size={32} round={true} />}
      />
      <WhatsappShareButton
        url={url}
        children={<WhatsappIcon size={32} round={true} />}
      />
      <TelegramShareButton
        url={url}
        children={<TelegramIcon size={32} round={true} />}
      />
      <ViberShareButton
        url={url}
        children={<ViberIcon size={32} round={true} />}
      />
      <RedditShareButton
        url={url}
        children={<RedditIcon size={32} round={true} />}
      />
      <WorkplaceShareButton
        url={url}
        children={<WorkplaceIcon size={32} round={true} />}
      />
      <EmailShareButton
        url={url}
        children={<EmailIcon size={32} round={true} />}
      />
    </div>
  )
}
